<template>
  <div v-if="apiOptions.vesslClosureUrl" class="blood-box">
    <div class="input-form-item">
      <div class="input-form-item-label">血管闭合方式</div>
      <cqt-select
        :isReadonly="isReadonly"
        :activeUrl="`/v1/webconsole/comboData/get/${apiOptions.vesslClosureUrl}`"
        :value.sync="aorticInfo.vesslClosure"
        @update:value="changeVesselClosure"
      />
    </div>

    <div v-if="blood_vessel_type_status" class="input-form-item">
      <div class="input-form-item-label">血管闭合方式其他</div>
      <cqt-input
        :isReadonly="isReadonly"
        class="input_has_unit"
        placeholder="请输入"
        type="text"
        size="mini"
        v-model="aorticInfo.vesslClosureOther"
      />
    </div>

    <div class="input-form-item" v-show="vesselObturator_status">
      <div class="input-form-item-label">血管闭合器种类</div>
      <div>
        <cqt-select
          :isReadonly="isReadonly"
          :activeUrl="`/v1/webconsole/comboData/get/${apiOptions.vesselObturatorUrl}`"
          :value.sync="aorticInfo.vesselObturator"
        />
      </div>
    </div>

    <div class="input-form-item" v-show="aorticInfo.vesselObturator === '其他'">
      <div class="input-form-item-label">血管闭合器其他种类</div>
      <div>
        <cqt-input
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          v-model="aorticInfo.vesselObturatorOther"
          size="mini"
          :isReadonly="isReadonly"
        ></cqt-input>
      </div>
    </div>

    <div class="input-form-item" v-show="aorticInfo.vesslClosure?.includes('封堵器')">
      <div class="input-form-item-label">封堵器类型</div>
      <div>
        <cqt-select
          :isReadonly="isReadonly"
          :activeUrl="`/v1/webconsole/comboData/get/${apiOptions.occluder_typeUrl}`"
          :value.sync="aorticInfo.occluderType"
        />
      </div>
    </div>

    <div
      class="input-form-item"
      v-show="aorticInfo.vesslClosure?.includes('封堵器') && aorticInfo.occluderType === '其他'"
    >
      <div class="input-form-item-label">封堵器其他类型</div>
      <div>
        <cqt-input
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          v-model="aorticInfo.occluderOtherType"
          size="mini"
          :isReadonly="isReadonly"
        ></cqt-input>
      </div>
    </div>

    <div class="input-form-item" v-show="aorticInfo.vesslClosure?.includes('缝合器')">
      <div class="input-form-item-label">缝合器类型</div>
      <div>
        <cqt-select
          :isReadonly="isReadonly"
          :activeUrl="`/v1/webconsole/comboData/get/${apiOptions.stapler_typeUrl}`"
          :value.sync="aorticInfo.staplerType"
        />
      </div>
    </div>

    <div
      class="input-form-item"
      v-show="aorticInfo.vesslClosure?.includes('缝合器') && aorticInfo.staplerType === '其他'"
    >
      <div class="input-form-item-label">缝合器其他类型</div>
      <div>
        <cqt-input
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          v-model="aorticInfo.staplerOtherType"
          size="mini"
          :isReadonly="isReadonly"
        ></cqt-input>
      </div>
    </div>
    <!-- -----------------------        压迫时间          --------------------- -->

    <div class="input-form-item" v-show="aorticInfo.vesslClosure?.includes('压迫器止血')">
      <div class="input-form-item-label">压迫时间</div>
      <div>
        <cqt-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="aorticInfo.pressureTime"
          size="mini"
          unit="min"
          min="0"
          :isReadonly="isReadonly"
        ></cqt-input>
      </div>
    </div>
  </div>
</template>

<script>
import CqtInput from '../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../components/cqt/CqtRadio.vue'

export default {
  name: 'BloodCloseType',
  components: {
    CqtInput,
    CqtSelect,
    CqtRadio
  },
  props: {
    isReadonly: {
      type: Boolean,
      require: true
    },
    surgeryInfo: {
      type: Object,
      default: () => {}
    },
    operation: {
      type: String,
      default: () => ''
    },
    apiOptions: {
      type: Object,
      default: () => {}
    }
  },
  created() {},
  computed: {},
  watch: {
    surgeryInfo: function (val) {
      if (val) {
        for (let item in val) {
          if (this.aorticInfo?.hasOwnProperty(item)) {
            this.aorticInfo[item] = val[item]
          }
        }
      }
    },
    apiOptions: {
      handler(val) {},
      immediate: true
    }
  },
  data() {
    return {
      operationId: undefined,
      aorticInfo: {
        vesslClosure: undefined, //血管闭合方式
        vesselObturator: undefined, //血管闭合器种类
        vesselObturatorOther: undefined, //血管闭合器种类其他
        vesslClosureOther: undefined, // 血管闭合方式其他

        occluderType: undefined, // 封堵器类型
        occluderOtherType: undefined, // 封堵器类型其他
        staplerType: undefined, // 缝合器类型
        staplerOtherType: undefined, // 缝合器类型其他
        pressureTime: undefined // 压迫时间
      },
      blood_vessel_type_status: false,
      vesselObturator_status: false
    }
  },
  mounted() {
    this.operationId = this.$route.query.operationId
  },
  methods: {
    changeVesselClosure(val) {
      if (val != undefined) {
        // 判断值和变量,如果之和变量相等,则不做操作,否则执行
        if (this.changeValue == val) {
          return
        } else {
          // 储存我所改变的值
          this.changeValue = val
          if (val?.includes('血管闭合器') || val?.includes('血管闭合装置')) {
            // 显示血管闭合器种类
            this.vesselObturator_status = true

            this.blood_vessel_type_status = false
            this.aorticInfo.vesslClosureOther = ''
            // this.aorticInfo.vesselObturator = ''
          } else if (val?.includes('其他')) {
            this.blood_vessel_type_status = true

            this.vesselObturator_status = false
            this.aorticInfo.vesselObturator = ''
          } else {
            this.vesselObturator_status = false
            this.aorticInfo.vesselObturator = ''

            this.blood_vessel_type_status = false
            this.aorticInfo.vesslClosureOther = ''
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.blood-box {
  display: flex;
  flex-direction: column;

  .input-form-item {
    min-width: 28%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;

    .input_has_unit {
      width: 200px;
      margin: 0;
    }

    .input_has_unit_text {
      margin: 0;
      width: 280px;
    }

    .el-date-editor.el-input {
      width: 200px;
    }
  }

  .input-form-item {
    min-width: 30%;
    margin: 0.4rem 0 0.4rem 0.5rem;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 40%;
    }
  }

  .input-form-item-label {
    width: 120px;
  }
}
</style>

<style scoped lang="less">
.valve-basic-information-muticards {
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 10px 10px 5px 10px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);

  .muticards {
    .el-card {
      margin: 10px 10px 10px 0;
    }

    .card_title {
      font-size: 16px;
      font-weight: 600;
    }

    .input-form-card {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .input-form-item {
      min-width: 30%;
    }
    .add-btn {
      font-size: 22px;
      display: flex;
      align-items: center;
      i {
        cursor: pointer;
      }
      .add-input {
        display: flex;
        margin-left: 5px;
      }
    }

    .card-complication {
      .is-bordered {
        margin: 5px 10px 5px 0;
      }
      .is-checked {
        border-color: #409eff !important;
        color: #409eff !important;
        /deep/.el-radio__label {
          color: #409eff !important;
        }
        /deep/.el-radio__inner {
          border-color: #409eff !important;
          background: #409eff !important;
        }
        /deep/.el-checkbox__label {
          color: #409eff !important;
        }
        /deep/.el-checkbox__inner {
          border-color: #409eff !important;
          background: #409eff !important;
        }
      }
    }
  }

  .valve-basic-label {
    margin: 15px;
    font-size: 16px;
    font-weight: bold;
  }

  .inner-title {
    position: absolute;
    top: -12px;
    left: 12px;
    background: #fff;
    padding: 0 10px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
  }

  .sty-edit {
    display: flex;
    align-items: center;
  }

  .save-button {
    padding-left: 10px;
  }

  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .input_has_xldl {
      /deep/.el-input__inner {
        border: none;
      }
    }

    .input_has_unit {
      margin: 0;
    }

    .input-form-item {
      margin: 0.4rem 0.5rem;
      display: flex;
      align-items: center;

      .input-form-item-label {
        text-align: center;
        width: 120px;
      }
    }
  }

  .input-form-item {
    min-width: 28%;
    /* margin: 0.4rem 1.5rem; */
    margin: 0.4rem 0 0.4rem 0.5rem;
    display: flex;
    align-items: center;

    .input_has_unit {
      margin: 0;
    }

    .el-date-editor.el-input {
      width: 200px;
    }
  }

  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 40%;
    }
  }

  .input-form-item-label {
    width: 136px;
  }

  .input-form-item-label-length {
    width: 150px;
  }
}
</style>

<template>
  <div class="valve-basic-information-muticards">
    <div class="muticards" style="display: flex">
      <el-card style="flex: 1" :body-style="{ padding: '20px' }">
        <div slot="header" class="card_title">
          <span>术后诊断</span>
        </div>
        <div v-if="complicationSHZDList.length > 0" class="card-complication">
          <el-radio-group :disabled="isReadonly" v-model="aorticInfo.postDiagnosis" size="mini">
            <el-radio
              v-for="(item, index) in complicationSHZDList"
              :key="index"
              :label="item.comboValue"
              border
            >
              {{ item.comboValue }}
            </el-radio>
          </el-radio-group>
          <div class="add-btn" v-show="addBtnIsShow">
            <el-button type="primary" size="mini" @click="addInputIsShow = true" circle>
              <i class="el-icon-plus"></i>
            </el-button>
            <div class="add-input" v-show="addInputIsShow">
              <el-input
                v-model="addInputValue"
                placeholder=""
                @keydown.native="handleKeydown"
              ></el-input>
            </div>
          </div>
          <!-- <div
            class="input-form-item"
            style="width: 300px"
            v-show="aorticInfo.postDiagnosis === '其他'"
          >
            <div class="input-form-item-label-length">并发症（其他）</div>
            <el-input v-model="aorticInfo.postDiagnosisOther" placeholder=""></el-input>
          </div> -->
        </div>
        <div v-else>
          <el-empty :image-size="70" description="暂无数据"></el-empty>
          <div class="add-btn" v-show="addBtnIsShow">
            <el-button type="primary" size="mini" @click="addInputIsShow = true" circle>
              <i class="el-icon-plus"></i>
            </el-button>
            <div class="add-input" v-show="addInputIsShow">
              <el-input
                v-model="addInputValue"
                placeholder=""
                @keydown.native="handleKeydown"
              ></el-input>
            </div>
          </div>
        </div>
      </el-card>
      <el-card style="flex: 1" :body-style="{ padding: '20px' }">
        <div slot="header" class="card_title">
          <span>并发症</span>
        </div>
        <div v-if="complicationList.length > 0" class="card-complication">
          <el-checkbox-group :disabled="isReadonly" v-model="aorticInfo.complication" size="mini">
            <el-checkbox
              v-for="(item, index) in complicationList"
              :key="index"
              :label="item.comboValue"
              border
            >
              {{ item.comboValue }}
            </el-checkbox>
          </el-checkbox-group>
          <div class="input-form-item">
            <div class="add-btn" v-show="addBtnComplicationIsShow">
              <el-button
                type="primary"
                size="mini"
                @click="addInputComplicationIsShow = true"
                circle
              >
                <i class="el-icon-plus"></i>
              </el-button>
              <div class="add-input" v-show="addInputComplicationIsShow">
                <el-input
                  v-model="addInputComplicationValue"
                  placeholder=""
                  @keydown.native="handleComplicationKeydown"
                ></el-input>
              </div>
            </div>
            <!-- <div class="input-form-item-label-length">并发症（其他）</div>
            <div>
              <cqt-input
                class="input_has_unit"
                placeholder="请输入"
                type="text"
                v-model="aorticInfo.complicationOther"
                size="mini"
                :isReadonly="isReadonly"
              ></cqt-input>
            </div> -->
          </div>
        </div>
        <div v-else>
          <el-empty :image-size="70" description="暂无数据"></el-empty>
          <div class="add-btn" v-show="addBtnComplicationIsShow">
            <el-button type="primary" size="mini" @click="addInputComplicationIsShow = true" circle>
              <i class="el-icon-plus"></i>
            </el-button>
            <div class="add-input" v-show="addInputComplicationIsShow">
              <el-input
                v-model="addInputComplicationValue"
                placeholder=""
                @keydown.native="handleComplicationKeydown"
              ></el-input>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import CqtInput from '../../../components/cqt/CqtInput.vue'

export default {
  name: 'ComplicationForm',
  components: {
    CqtInput
  },
  props: {
    complicationData: {
      type: Object
    },
    menuListData: {
      type: Object,
      default: () => ({})
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    },
    complicationUrl: {
      // 并发症
      type: String,
      default: () => '/v1/webconsole/comboData/get/gmzy_jbxx_bfz'
    },
    finalDiagnosisUrl: {
      // 术后诊断
      type: String,
      default: () => '/v1/webconsole/comboData/get/AO_postoperative_diagnosis'
    }
  },
  data() {
    return {
      complicationSHZDList: [], // 并发症---术后诊断
      complicationList: [], // 并发症---并发症
      addInputValue: '',
      addInputComplicationValue: '',
      addBtnIsShow: false,
      addInputIsShow: false,
      addBtnComplicationIsShow: false,
      addInputComplicationIsShow: false,

      aorticInfo: {
        complication: [],
        postDiagnosis: ''
        // postDiagnosisOther: '',
        // complicationOther: null // 并发症其他
      }
    }
  },
  created() {},
  watch: {
    complicationData: {
      handler(val) {
        if (Object.keys(val).length > 0) {
          this.aorticInfo = {
            ...this.aorticInfo,
            // complicationOther: val?.complicationOther,
            // postDiagnosisOther: val?.postDiagnosisOther,
            postDiagnosis: val?.postDiagnosis,
            complication: val?.complication?.split('|') || []
          }
        }
      },
      immediate: true,
      deep: true
    },
    complicationUrl: {
      handler(val) {
        if (val) {
          this.getComplication()
        }
      },
      immediate: true
    },
    finalDiagnosisUrl: {
      handler(val) {
        if (val) {
          this.getFinalDiagnosisUrl()
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    handleKeydown(event) {
      const keyCode = event.keyCode
      // Enter
      if (keyCode === 13) {
        this.addDetail()
        event.stopPropagation()
        return
      }
    },
    addDetail() {
      let formData = new FormData()
      let url = ''
      if (this.menuListData.code) {
        url = `/v1/webconsole/study/add/dictionary/${this.menuListData.code}`
        formData.append('type', 'postDiagnosis')

        formData.append('value', this.addInputValue)
      } else {
        url = `/v1/webconsole/comboData/add/detail/${this.complicationSHZDList[0].comboUID}`
        formData.append('comboValue', this.addInputValue)
      }
      this.$api.post(url, formData).then((res) => {
        this.$message.success(res.data.msg)
        this.getFinalDiagnosisUrl()
        this.addInputValue = ''
      })
    },
    handleComplicationKeydown(event) {
      const keyCode = event.keyCode
      // Enter
      if (keyCode === 13) {
        this.addComplicationDetail()
        event.stopPropagation()
        return
      }
    },
    addComplicationDetail() {
      let formData = new FormData()
      let url = ''
      if (this.menuListData.code) {
        url = `/v1/webconsole/study/add/dictionary/${this.menuListData.code}`
        formData.append('type', 'complication')

        formData.append('value', this.addInputComplicationValue)
      } else {
        url = `/v1/webconsole/comboData/add/detail/${this.complicationList[0].comboUID}`
        formData.append('comboValue', this.addInputComplicationValue)
      }
      this.$api.post(url, formData).then((res) => {
        this.$message.success(res.data.msg)
        this.getComplication()
        this.addInputComplicationValue = ''
      })
    },
    // 并发症
    getComplication() {
      if (!this.complicationUrl) return
      this.$api.get(this.complicationUrl).then(
        (res) => {
          if (res.data && res.data.data) {
            this.complicationList = res.data.data
            if (this.complicationList.length === 0 && !this.menuListData.code) {
              this.addBtnComplicationIsShow = false
            } else {
              this.addBtnComplicationIsShow = true
            }
          }
        },
        (err) => console.error(err)
      )

      this.addInputComplicationIsShow = false
    },
    // 术后诊断
    getFinalDiagnosisUrl() {
      if (!this.finalDiagnosisUrl) return
      this.$api.get(this.finalDiagnosisUrl).then(
        (res) => {
          if (res.data && res.data.data) {
            this.complicationSHZDList = res.data.data
            if (this.complicationSHZDList.length === 0 && !this.menuListData.code) {
              this.addBtnIsShow = false
            } else {
              this.addBtnIsShow = true
            }
          }
        },
        (err) => console.error(err)
      )

      this.addInputIsShow = false
    }
  },
  computed: {}
}
</script>

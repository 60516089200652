<template>
  <el-card class="undo-redo-select-main-content">
    <div class="undo-redo-select-main-content-head">
      <div class="template-select">
        <div class="template-select-text">
          <span>{{ templateData?.templateName }}</span>
        </div>

        <el-select
          v-if="!isReadOnly"
          v-model="templateValue"
          placeholder="请选择模板"
          @change="getModal"
          :disabled="isReadOnly"
        >
          <el-option
            v-for="item in templateList"
            :key="item?.uuid"
            :label="item.title"
            :value="item.title"
          ></el-option>
        </el-select>
        <span v-else>{{ templateValue }}</span>
      </div>
      <div class="undo-button">
        <el-button
          type="warning"
          @click="undo"
          :disabled="isReadOnly"
          v-show="!isReadOnly"
          class="common-button commonBtn"
        >
          撤销
        </el-button>
      </div>
    </div>

    <div class="undo-redo-select-main-content-body">
      <el-card class="box-card">
        <cqt-input :isReadonly="isReadOnly" type="textarea" :rows="8" v-model="treatmentPlan" />
      </el-card>
    </div>
  </el-card>
</template>

<script>
import CqtInput from '../../../components/cqt/CqtInput.vue'

export default {
  components: {
    CqtInput
  },
  props: {
    templateData: {
      type: Object,
      default: null
    },
    isReadOnly: {
      type: Boolean,
      require: true
    },
    surgeryInfo: {
      type: Object,
      default: null
    },
    operation: {
      type: String,
      default: () => ''
    }
  },

  mounted() {},

  computed: {},
  watch: {
    surgeryInfo: {
      handler(val) {
        this.treatmentPlan = val?.treatmentPlan
        if (val.treatmentPlanId) {
          for (let i = 0; i < this.templateList.length; i++) {
            const element = this.templateList[i]
            if (val.treatmentPlanId === element.uuid) {
              this.templateValue = element.title
            }
          }
        }
      }
    },
    operation: {
      handler(val) {
        if (val) {
          this.initData()
        }
      },
      immediate: true
    }
  },

  data() {
    return {
      templateList: [],
      treatmentPlanId: '',
      templateValue: null,
      treatmentPlan: null
    }
  },
  methods: {
    initData() {
      this.$api
        .get('/v1/webconsole/study/treatmentplan', {
          moduleCode: this.operation
        })
        .then(
          (res) => {
            if (res.data && res.data.data) {
              this.templateList = res.data.data
            }
          },
          (err) => console.log(err)
        )
    },
    undo() {
      if (this.isReadOnly) {
        return this.$message({
          message: '报告已签名,无法进行编辑操作',
          type: 'warning'
        })
      }
      this.treatmentPlan = ''
      this.templateValue = ''
      this.treatmentPlanId = undefined
    },

    async getModal(val) {
      for (let i = 0; i < this.templateList.length; i++) {
        const element = this.templateList[i]
        if (val === element.title) {
          this.treatmentPlanId = element.uuid
          this.templateValue = element.title
          this.treatmentPlan = element.treatmentPlan
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.undo-redo-select-main-content {
  margin-bottom: 30px;
  margin-top: 30px;

  .undo-redo-select-main-content-head {
    height: 50px;
    display: flex;
    align-items: center;

    .template-select {
      height: 3rem;
      line-height: 3rem;
      display: flex;

      .el-select {
        width: 300px;
      }

      .el-select {
        width: 200px;
      }

      .template-select-text {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .undo-button {
      padding-left: 10px;
    }
  }
}
</style>
